<template>
  <b-container fluid>
    <b-row>
      <b-col cols="12">
        <page-snippet name="documentation-header" />
      </b-col>

      <b-col lg="4" md="6" cols="12" class="mt-4">
        <b-card header="New features">
          <page-snippet name="new-functionality" class="" />
        </b-card>
      </b-col>

      <b-col lg="4" md="6" cols="12" class="mt-4">
        <b-card header="Features coming in the future">
          <page-snippet name="roadmap" class="" />
        </b-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
  export default {
    name: 'News',
    components: {
      'page-snippet': () => import('@/components/PageSnippet'),
    },
    props: {
    },
    mounted () {
    }
  }
</script>