var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "b-row",
        [
          _c(
            "b-col",
            { attrs: { cols: "12" } },
            [_c("page-snippet", { attrs: { name: "documentation-header" } })],
            1
          ),
          _c(
            "b-col",
            { staticClass: "mt-4", attrs: { lg: "4", md: "6", cols: "12" } },
            [
              _c(
                "b-card",
                { attrs: { header: "New features" } },
                [_c("page-snippet", { attrs: { name: "new-functionality" } })],
                1
              )
            ],
            1
          ),
          _c(
            "b-col",
            { staticClass: "mt-4", attrs: { lg: "4", md: "6", cols: "12" } },
            [
              _c(
                "b-card",
                { attrs: { header: "Features coming in the future" } },
                [_c("page-snippet", { attrs: { name: "roadmap" } })],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }